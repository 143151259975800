@import "../core/variables" ;

// Colore di background intestazione griglia
$grid-header-background-color: #fafafa;
// Colore di background hover intestazione griglia
$grid-header-hover-background-color: #eee;
// Colore bordi intestazione griglia
$grid-header-border-color: #e0e0e0;
// Colore all'hover dei pulsanti espandi a sx/dx
$grid-highlight-color: $main-tag-color;
// Colore dell'icona del raggruppamento nell'header delle griglie
$grid-group-column-header-icon-color: #b0b0b7;
// Colore di selezione della riga
$grid-selection-color: #b0b0b7;
// Colore di background del pannello filtri
$panel-background-color: #fafafa;