@import "../core/variables" ;

$primary-color: #2979ff;
$primary-color-hover: rgba(41, 121, 255, 0.92);
$bg-color-num-today: #84B6FF;

.b-monthview .b-weeks-container .b-calendar-row .b-week-number-cell.b-today,
.b-monthview .b-weeks-container .b-calendar-row .b-calendar-cell.b-today {

  .b-day-name {
    strong {
      color: $primary-color;
    }
  }

  .b-day-num {
    background-color: $primary-color;
  }

  &:hover {
    .b-day-num {
      background-color: $primary-color-hover;
    }
  }
}
.b-calendarrow .b-cal-cell-header.b-today.b-current-date {

  .b-day-name-date {
    background-color: $primary-color;
  }

  &:hover {
    .b-day-name-date {
      background-color: $primary-color-hover;
    }
  }
}

.b-calendarrow .b-cal-cell-header.b-today .b-day-name-date {
  background-color: $bg-color-num-today;

  &:hover {
    background-color: $primary-color;
  }
}

.b-cal-event-wrap {
  // Non all day events *which have no bar* get a bar on hover
  &.b-intraday:not(.b-solid-bar) .b-cal-event {
    background-color: var(--cal-event-color);
    color: white;
    opacity: .8;

    &:hover {
      background: var(--cal-event-color);
      opacity: 1;
    }
  }
}

.b-monthview .b-week-number-cell.b-other-month,
.b-monthview .b-calendar-cell.b-other-month {
  .holiday {
    .b-day-num {
      color: inherit !important;
      opacity: .7;
    }
  }
}