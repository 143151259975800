@import "variables" ;

.b-list.b-combo-picker {
  border: 0 none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.b-pickerfield {
  &.b-open {
    .b-field-inner {
      border-color: $main-tag-color !important;
    }
  }
}

.b-combo {
  .b-field-inner {
    overflow: auto;
  }
}