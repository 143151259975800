.b-schedulerpro, .b-schedulerpro-body-wrap {
  background-color: #ffff !important;
  border-radius: .75rem;
}

.b-top-toolbar {
  margin-bottom: .5rem;
  border-radius: 0.75rem;
}

.b-grid-panel-body, .b-button, .b-button:not(.b-tab) {
  border-radius: 0.75rem;
}

.timeline-event-tooltip.b-panel.b-html {
  .b-panel-content {
    margin-block: 0;
    padding-block: 0;
    padding: 14px;
  }
}

.b-timeline-subgrid .b-sch-range {
  background-color: #fee2e24d;
}

.b-timeranges-header-canvas .b-sch-nonworkingtime {
  background-color: #fee2e24d;
}

.b-monthview-overflowpopup {
  .b-overflowpopup-body-wrap {
    background-color: #ffff !important;
    .b-overflowpopup-content {
      margin: .5rem !important;
    }
  }
}

.b-sch-event:not(.b-milestone) .b-sch-event-content {
  height: 100%;
}
