$fa-font-path: "@bryntum/core-thin/fonts";
$roboto-font-path: "@bryntum/core-thin/fonts";

@import "./core/variables";
@import "./grid/variables";
@import "./calendar/variables";
@import "./scheduler-pro/variables";

@import "@bryntum/core-thin/sass/themes/classic-light.scss";
@import "@bryntum/grid-thin/sass/themes/classic-light.scss";
@import "@bryntum/scheduler-thin/sass/themes/classic-light.scss";
@import "@bryntum/schedulerpro-thin/sass/themes/classic-light.scss";
@import "@bryntum/calendar-thin/sass/themes/classic-light.scss";

.b-blue {
  --widget-primary-color-rgb: #{hexToRGBString($main-tag-color)};
}

.b-gray {
  --widget-primary-color-rgb: 117, 117, 117;
}

.b-green {
  --widget-primary-color-rgb: 34, 197, 94;
}

.b-slidetoggle {
  --widget-primary-color-rgb: #{hexToRGBString($main-tag-color)};
}

.b-checkbox {
  --widget-primary-color-rgb: #{hexToRGBString($main-tag-color)};
}

.b-panel.b-tooltip .b-panel-body-wrap {
  background-color: #75757f;
}

.b-tooltip-content {
  color: #fff;
}

.b-lazyloadview {
  transform: translate3d(0, 0, 0);
}

.b-hide-scroll {
  transform: translate3d(0, 0, 0);
}